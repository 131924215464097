<template>
  <el-main>
    <el-form ref="form" :model="form" :rules="rules" label-width="130px">
      <el-form-item label="地理围栏商圈：" prop="trading_area">
        <el-input v-model="form.trading_area" placeholder="围栏标题"></el-input>
      </el-form-item>
      <el-form-item label="经纬度：" class="longitude" prop="longitude">
        <el-input v-model="form.longitude"></el-input>
        <el-input v-model="form.latitude"></el-input>
        <el-button type="primary" @click="mapFlag = !0">定位</el-button>
      </el-form-item>
      <el-form-item label="商圈范围：" prop="scope">
        <el-input v-model="form.scope" :min="0" :max="5000" type="number" placeholder="请填写最大商圈范围（200-5000），不填写默认200米"></el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-radio-group v-model="form.status">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="0">停用</el-radio>
        </el-radio-group>
      </el-form-item>
      <Preservation @preservation="submit('form')">
        <router-link to="/extension/enclosureList" slot="return" style="margin-right: 10px">
          <el-button>返回</el-button>
        </router-link>
      </Preservation>
    </el-form>
    <!-- 地图弹框 -->
    <el-dialog title="提示" :visible.sync="mapFlag" width="52%">
      <div>
        <MapData ref="map" :lng="form.longitude" :lat="form.latitude"></MapData>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getAddress">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择链接" :visible.sync="pageFlag" width="52%">
      <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
    </el-dialog>
  </el-main>
</template>

<script>
import MapData from '../store/components/Map';
import ToPage from '@/views/addPages/toPage/index';
import Preservation from '@/components/preservation';
export default {
  components: {
    MapData,
    ToPage,
    Preservation,
  },
  data() {
    const verifyScope = (rule, value, callback) => {
      if (value && (value > 5000 || value < 200)) {
        callback(new Error('请填写最大商圈范围（200-5000）'));
      } else {
        callback();
      }
    };
    return {
      mapFlag: !1,
      pageFlag: !1,
      form: {
        longitude: '',
        latitude: '',
        scope: '',
        trading_area: '',
        status: 1,
      },
      rules: {
        trading_area: [{ required: true, message: '请填写围栏标题', trigger: 'blur' }],
        longitude: [{ required: true, message: '请设置经纬度', trigger: 'blur' }],
        scope: [
          {
            validator: verifyScope,
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    let info = this.$route.query.info;
    if (info) {
      let utils = this.$store.state.utils;
      utils.page = Number(this.$route.query.page);
      utils.rows = Number(this.$route.query.rows);
      utils.is_record = !0;
      info = JSON.parse(info);
      delete info.create_time;
      delete info.marking;
      this.form = info;
    }
  },
  methods: {
    // 获取选中的经纬度
    getAddress() {
      let obj = this.$refs.map.currentCoordinate;
      this.form.latitude = obj.lat;
      this.form.longitude = obj.lng;
      this.mapFlag = !1;
    },
    selectPath() {
      this.pageFlag = !0;
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr();
      });
    },
    // 拿到跳转路径
    getPageRes(item) {
      this.form.jump_path = item.wxroute;
      this.pageFlag = !1;
    },
    submit(val) {
      this.$refs[val].validate(valid => {
        if (valid) {
          let form = Object.assign({}, this.form);
          if (!form.scope) form.scope = 200;
          let url = form.id ? this.$api.push.editEnclosure : this.$api.push.addEnclosure;
          this.$axios.post(url, form).then(res => {
            if (res.code == 0) {
              this.$router.push("/extension/enclosureList")
              this.$message({
                message: form.id ? '编辑成功' : '保存成功',
                type: 'success',
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  height: 100%;
  background: #fff;
  padding: 50px 70px;
  .el-form {
    width: 700px;
    .longitude {
      /deep/ .el-form-item__content {
        display: flex;
        .el-input {
          flex: 1;
          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
    .el-form-item:nth-child(2) .el-button {
      margin-left: 10px;
    }
  }
  .map {
    width: 100%;
    height: 500px;
  }
}
</style>
